import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';

interface Props {
  company?: string;
}

function FooterMarkdown(props: Props) {
  return (
    <ReactMarkdown
      components={{
        h1: (props) => (
          <p className="text-body1 text-white mb-4">{props.children}</p>
        ),
        h2: (props) => (
          <p className="text-body1 text-white mb-6">{props.children}</p>
        ),
        p: (props) => (
          <p className="text-body3 opacity-80 text-white">{props.children}</p>
        ),
      }}
      rehypePlugins={[rehypeRaw, rehypeSanitize]}
      children={props.company}
    />
  );
}

export default FooterMarkdown;
